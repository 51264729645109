const insertStaticElement = (header, footer) => {
  const headerElement = document.getElementById('crmn_header');
  if (headerElement && header) {
    headerElement.innerHTML = header;
  } else if (headerElement) {
    headerElement.innerHTML = null;
  }

  const footerElement = document.getElementById('crmn_footer');
  if (footerElement && footer) {
    footerElement.innerHTML = footer;
  } else if (footerElement) {
    footerElement.innerHTML = null;
  }
};

export default insertStaticElement;
