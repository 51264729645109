import useCommonStore from '@/store/common';

import FairRepository from './fair-repository';
import PlanRepository from './plan-repository';
import ScheduleRepository from './schedule-repository';
import SettingRepository from './setting-repository';
import FormItemSettingRepository from './form-item-setting-repository';

const repositories = {
  fairs: FairRepository,
  plans: PlanRepository,
  schedules: ScheduleRepository,
  settings: SettingRepository,
  formItemSettings: FormItemSettingRepository
};

export default {
  get: (name) => {
    const { token } = useCommonStore();
    return repositories[name](token);
  }
};
