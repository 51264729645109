import { defineStore } from 'pinia';

const useCommonStore = defineStore('common', {
  state: () => ({
    token: null,
    appendingTag: true,
    loading: false
  }),
  actions: {
    updateAppendingTag(value) {
      this.appendingTag = value;
    },
    updateLoading(loading) {
      this.loading = loading;
    }
  }
});

export default useCommonStore;
