import { defineStore } from 'pinia';

import state from './state';
import getters from './getters';
import actions from './actions';

const store = { state, getters, actions };
const useFairReservation = defineStore('fair-reservation', store);

export default useFairReservation;
