import { defineStore } from 'pinia';
import Color from 'color';

const validateColor = (value) => {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return value.match(regex) !== null;
};

const useStyleStore = defineStore('style', {
  state: () => ({
    mainColor: '#d43a6c',
    keyColor: '#513823',
    accentColor: '#d0a820',
    navigationColor: '#d43a6c',
    textColor: '#000000'
  }),
  getters: {
    imageBgColor: ({ mainColorBg }) => mainColorBg.fade(0.8),
    mainColorBg() {
      const color = Color(this.mainColor);
      return color.fade(0.8);
    },
    keyColorBg() {
      const color = Color(this.keyColor);
      return color.fade(0.8);
    },
    accentColorBg() {
      const color = Color(this.accentColor);
      return color.fade(0.8);
    },
    accentColorBgLight() {
      const color = Color(this.accentColor);
      return color.desaturate(0.5).lighten(1);
    },
    benefitColor() {
      return this.accentColor;
    },
    benefitSubColor() {
      const color = Color(this.accentColor);
      return color.darken(0.3);
    }
  },
  actions: {
    setting({ accents_color, key_color, main_color, navigation_color, text_color }) {
      this.mainColor = main_color;
      this.keyColor = key_color;
      this.accentColor = accents_color;
      this.navigationColor = navigation_color;
      this.textColor = text_color;
    },
    preview(params) {
      const paramsValidator = {
        mainColor: validateColor,
        keyColor: validateColor,
        accentColor: validateColor,
        navigationColor: validateColor,
        textColor: validateColor
      };
      const converter = {
        mainColor: (value) => value,
        keyColor: (value) => value,
        accentColor: (value) => value,
        navigationColor: (value) => value,
        textColor: (value) => value
      };

      Object.entries(params).forEach(([key, value]) => {
        if (!Object.keys(paramsValidator).includes(key)) return;
        const validator = paramsValidator[key];
        const isValid = validator ? validator(value) : true;

        if (isValid) this[key] = converter[key](value);
      });
    }
  }
});

export default useStyleStore;
