<template>
  <div class="wrapper">
    <div class="loading">
      <div class="spinner">
        <i v-for="n in 10" :key="n" class="spinner-item" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 400;
  color: variables.$COLOR_TEXT;
  background-color: rgba(variables.$COLOR_GREY, 0.8);
}

.loading {
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.spinner {
  $spinnerSize: 36px;
  $dotSize: 6px;

  height: $spinnerSize;
  width: $spinnerSize;
  display: inline-block;
  margin: -($spinnerSize * 0.5) auto 0;
  animation: spin 1s steps(10, end) infinite;

  &-item {
    height: $dotSize;
    width: $dotSize;
    margin-left: -($dotSize * 0.5);
    display: block;
    transition: height 1s;
    position: absolute;
    left: 50%;
    transform-origin: center ($spinnerSize * 0.5);
    background: #fff;
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.7);
    border-radius: $dotSize;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        $deg: calc(36deg * #{$i});
        $opacity: calc(0.1 * #{$i});
        transform: rotate($deg);
        opacity: $opacity;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
