import Repository from './repository';

export default (token) => {
  const resource = 'schedules';
  const { index } = Repository(resource, token);

  // APIのコントローラーのルーティングに合わせる
  const { index: calendarIndex } = Repository('calendar', token);

  return { index, calendarIndex };
};
