import { toQueryString } from '@/helper/common/object';
import Repository, { getAxiosInstance } from './repository';

export default (token) => {
  const resource = 'fairs';
  const { index, show } = Repository(resource, token);
  return {
    show,
    index: (query = {}) => {
      const enableParams = [
        'fair_id',
        'text',
        'date',
        'start_time',
        'end_time',
        'contents',
        'tags',
        'recommended',
        'per_page',
        'page'
      ];
      const queryString = toQueryString(query, enableParams);
      return index(queryString);
    },
    dailyIndex: async (query = {}) => {
      const enableParams = [
        'fair_id',
        'text',
        'date',
        'start_time',
        'end_time',
        'contents',
        'tags',
        'recommended',
        'per_page',
        'page'
      ];
      const queryString = toQueryString(query, enableParams);
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}/daily${queryString}`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    },
    dailyShow: async (id, date) => {
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}/${id}/daily/${date}`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    },
    definition: async () => {
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}/definition`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    }
  };
};
