import useFairStore from '@/store/fair';
import useSettingStore from '@/store/setting';
import useScheduleStore from '@/store/schedule';

import insertStaticElement from './insert-static-element';

const Fairs = () => import(/* webpackChunkName: "fairs" */ '@/views/Fairs.vue');
const DailyFairs = () => import(/* webpackChunkName: "daily-fairs" */ '@/views/DailyFairs.vue');

const fairs = {
  path: '/fairs',
  name: 'fairs',
  component: () => {
    const { isDaily } = useFairStore();
    return isDaily ? DailyFairs() : Fairs();
  },
  beforeEnter: async (to, _, next) => {
    const { fetchInitialFairs } = useFairStore();
    await fetchInitialFairs(to.query);

    const { fetchSchedules, fetchCalendar } = useScheduleStore();
    fetchSchedules();
    fetchCalendar();

    const { fairListHeader, fairListFooter } = useSettingStore();
    insertStaticElement(fairListHeader, fairListFooter);
    next();
  }
};

export default fairs;
