import { defineStore } from 'pinia';

import { fetchToken, getAxiosInstance } from '@/repositories/repository';
import { toLocaleString } from '@/helper/common/date';

const useContact = defineStore('contact', {
  state: () => ({
    planName: null,
    contactType: 1,
    familyName: null,
    firstName: null,
    familyNameRuby: null,
    firstNameRuby: null,
    tel: null,
    mailAddress: null,
    question: null,
    firstRequestDate: {
      date: null,
      time: null
    },
    secondRequestDate: {
      date: null,
      time: null
    },
    thirdRequestDate: {
      date: null,
      time: null
    },
    numberOfPeople: '2'
  }),
  getters: {
    numberOfPeopleOptions: () => [
      { value: '1', label: '1人' },
      { value: '2', label: '2人' },
      { value: '3', label: '3人' },
      { value: '4', label: '4人' },
      { value: '5', label: '5人' }
    ],
    contactTypes() {
      return [
        { value: 1, label: 'お問い合わせ', query: 'inquiry' },
        { value: 2, label: '来館予約', query: 'visit-reservation' }
      ];
    },
    selectedContactType() {
      const contactType = this.contactTypes.find(({ value }) => value === this.contactType);
      return contactType ? contactType.label : '';
    },
    requestDateAtConfirm() {
      return ({ date, time }) => {
        const japaneseDate = toLocaleString(date, 'なし');
        return `${japaneseDate}${japaneseDate ? ' ' : ''}${time || ''}`;
      };
    },
    contactParams() {
      return {
        family_name: this.familyName,
        first_name: this.firstName,
        family_name_kana: this.familyNameRuby,
        first_name_kana: this.firstNameRuby,
        phone_number: this.tel,
        mail_address: this.mailAddress,
        comment: this.question,
        plan_name: this.planName
      };
    },
    reservationParams() {
      return {
        ...this.contactParams,
        visit_datetime: [this.firstRequestDate, this.secondRequestDate, this.thirdRequestDate],
        number_of_attendees: this.numberOfPeople
      };
    }
  },
  actions: {
    async postContact() {
      const token = await fetchToken();
      const axios = getAxiosInstance(token);
      const isContact = this.contactType === 1;
      const resource = isContact ? 'contact' : 'visit-reservation';
      const params = isContact ? this.contactParams : this.reservationParams;
      try {
        return await axios.post(resource, params);
      } catch (e) {
        return false;
      }
    },
    updatePlanName(planName) {
      this.planName = planName;
    },
    updateContactType(value) {
      this.contactType = value;
    }
  }
});

export default useContact;
