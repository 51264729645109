import useSettingStore from '@/store/setting';

import insertStaticElement from './insert-static-element';

const Policies = () => import(/* webpackChunkName: "policies-top" */ '@/views/policies/Index.vue');

const policies = {
  path: '/policies',
  name: 'policies',
  component: Policies,
  beforeEnter: async (_, __, next) => {
    const { otherHeader, otherFooter } = useSettingStore();
    insertStaticElement(otherHeader, otherFooter);
    next();
  }
};

export default policies;
