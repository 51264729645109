import { defineStore } from 'pinia';

import useCommonStore from '@/store/common';

import factory from '@/repositories/repository-factory';

const usePlanStore = defineStore('plan', {
  state: () => ({
      plans: [],
      recommendedPlans: [],
      links: {},
      meta: {},
      definition: {}
    }),
  getters: {
    // definitionから各種定義情報を取得する
    defContents() {
      return this.definition.contents;
    },
    defPlanTypes() {
      return this.definition.plan_types;
    },
    defTags() {
      return this.definition.tags;
    }
  },
  actions: {
    async fetchPlansByParams(query = {}) {
      const { updateLoading } = useCommonStore();
      updateLoading(true);
      const repository = factory.get('plans');
      const { links, meta, data: plans } = await repository.index(query);
      this.$patch({ links, meta, plans });
      updateLoading(false);
    }
  }
});

export default usePlanStore;
