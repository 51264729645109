import { format, startOfToday } from 'date-fns';

import { fetchToken, getAxiosInstance } from '@/repositories/repository';

import { getValue } from '@/helper/common/object';
import { isValid, newDate } from '@/helper/common/date';

const actions = {
  preview(needTimetable) {
    const selectedDate = getValue(this.currentFair, 'schedules', '0', 'date');
    this.selectedDate = isValid(selectedDate) ? selectedDate : format(startOfToday(), 'yyyy-MM-dd');

    if (needTimetable) {
      const requestTimetable = getValue(
        this.currentFair,
        'parts_timetables',
        '0',
        'parts_timetable_id'
      );

      if (!requestTimetable) return;

      this.requestTimetable = requestTimetable;
      this.checkReservableContents(this.requestTimetable);
    }
  },
  setShowOptionalItems(showOptionalItems) {
    this.showOptionalItems = showOptionalItems;
  },
  setSelectedDate(date) {
    if (!isValid(date)) return;
    const selectedDate = format(newDate(date), 'yyyy-MM-dd');

    if (!this.isValidSelectDate(selectedDate)) return;
    this.selectedDate = selectedDate;
  },
  setRequestTimetable(requestTimetable) {
    if (!this.timetable(requestTimetable)) return;

    const part = this.partByTimetable(requestTimetable);
    if (this.isStockByPartFull(part)) return;

    this.requestTimetable = requestTimetable;
    this.checkReservableContents(requestTimetable);
  },
  checkReservableContents(requestTimetable) {
    if (!this.isStockByContent) return;

    const isStockFull = this.isStockByContentFull;
    const part = this.partByTimetable(requestTimetable);
    this.requestContents = this.contentsTimetable(part)
      .filter((content) => !isStockFull(content.part, content.content_id))
      .map(({ contents_timetable_id }) => contents_timetable_id);
  },
  async postReservation() {
    const token = await fetchToken();
    const axios = getAxiosInstance(token);
    try {
      return await axios.post('fair-reservation', this.params);
    } catch (e) {
      return false;
    }
  }
};

export default actions;
