import { defineStore } from 'pinia';
import factory from '@/repositories/repository-factory';
import useStyleStore from '@/store/style';
import useContact from '@/store/contact';
import SHOWING_SETTING from '@/constants/showing-setting';

// クエリのキーから対応するアクション名を取得する
const queryKeyToActionName = (key) => `update${key[0].toUpperCase()}${key.slice(1)}`;

const useSettingStore = defineStore('setting', {
  state: () => ({
    isPreviewActivated: false,
    basic: {
      fair_list_type: null
    },
    layout: {
      header: null,
      footer: null,
      menu: null
    },
    contact: {
      phone_number: null,
      reception_time: null
    },
    tag: {
      link_tag: null,
      script_tag_head: null,
      script_tag_body: null,
      no_script_tag: null,
      meta_tag: null,
      favicon_url: null,
      style_tag: null
    },
    form: {
      business_day_description: null,
      fair_reservation: {
        should_show_optional_items: null,
        supplementary_text: null,
        completion_message: null,
        link_name: null,
        link_url: null,
      },
      contact: {
        supplementary_text: null,
        completion_message: null,
        link_name: null,
        link_url: null,
      },
      visit_reservation: {
        supplementary_text: null,
        completion_message: null,
        link_name: null,
        link_url: null,
      }
    },
    design: {
      show_header_section: SHOWING_SETTING.SHOW,
      show_fair_description: SHOWING_SETTING.SHOW
    },
    page: {
      privacy_policy_link: null,
      fair_list_title: null,
      fair_detail_title: null,
      plan_list_title: null,
      contact_page_title: null
    },
    meta: {
      meta_description: null,
      meta_keyword: null,
      use_index: true
    },
    master: {
      font_type: {
        1: 'メイリオ',
        2: 'ゴシック',
        3: '明朝'
      }
    },
    hallInfo: {
      is_demo_account: false,
      is_service_started: false
    },
    static_elements: {
      fair_list_header: null,
      fair_detail_header: null,
      plan_header: null,
      fair_reservation_header: null,
      contact_header: null,
      other_header: null,
      fair_list_footer: null,
      fair_detail_footer: null,
      plan_footer: null,
      fair_reservation_footer: null,
      contact_footer: null,
      other_footer: null
    },
    fetching: true
  }),
  getters: {
    fairListType: ({ basic }) => basic.fair_list_type || 1,
    fairListLink: ({ fairListType }) => (Number(fairListType) === 1 ? '/fairs' : '/daily-fairs'),
    phoneNumber: ({ contact }) => (contact && contact.phone_number) || null,
    contactReceptionTime: ({ contact }) => (contact && contact.reception_time) || null,
    showHeaderSection: ({ design }) => design.show_header_section === SHOWING_SETTING.SHOW,
    showFairDescription: ({ design }) => design.show_fair_description === SHOWING_SETTING.SHOW,
    businessDayDescription: ({ form }) => form.business_day_description,
    // フェア予約
    fairShouldShowOptionalItems: ({ form }) => form.fair_reservation.should_show_optional_items,
    fairQaNotes: ({ form }) => form.fair_reservation.supplementary_text,
    fairDoneBookingMsg: ({ form }) => form.fair_reservation.completion_message,
    fairLinkName: ({ form }) => form.fair_reservation.link_name,
    fairLinkUrl: ({ form }) => form.fair_reservation.link_url,
    // 問い合わせ
    contactQaNotes: ({ form }) => form.contact.supplementary_text,
    contactDoneBookingMsg: ({ form }) => form.contact.completion_message,
    contactLinkName: ({ form }) => form.contact.link_name,
    contactLinkUrl: ({ form }) => form.contact.link_url,
    // 来館予約
    visitQaNotes: ({ form }) => form.visit_reservation.supplementary_text,
    visitDoneBookingMsg: ({ form }) => form.visit_reservation.completion_message,
    visitLinkName: ({ form }) => form.visit_reservation.link_name,
    visitLinkUrl: ({ form }) => form.visit_reservation.link_url,
    // 静的要素の取得
    contactHeader: ({ static_elements }) => static_elements.contact_header,
    contactFooter: ({ static_elements }) => static_elements.contact_footer,
    fairDetailHeader: ({ static_elements }) => static_elements.fair_detail_header,
    fairDetailFooter: ({ static_elements }) => static_elements.fair_detail_footer,
    fairListHeader: ({ static_elements }) => static_elements.fair_list_header,
    fairListFooter: ({ static_elements }) => static_elements.fair_list_footer,
    fairReservationHeader: ({ static_elements }) => static_elements.fair_reservation_header,
    fairReservationFooter: ({ static_elements }) => static_elements.fair_reservation_footer,
    planHeader: ({ static_elements }) => static_elements.plan_header,
    planFooter: ({ static_elements }) => static_elements.plan_footer,
    otherHeader: ({ static_elements }) => static_elements.other_header,
    otherFooter: ({ static_elements }) => static_elements.other_footer,

    // index まわり
    indexable: ({ hallInfo }) => !hallInfo.is_demo_account && hallInfo.is_service_started,
    useIndexFairList: ({ indexable, meta }) => indexable && meta.use_index.fair_list,
    useIndexFairDetail: ({ indexable, meta }) => indexable && meta.use_index.fair_detail,
    useIndexPlan: ({ indexable, meta }) => indexable && meta.use_index.plan,
    useIndexContact: ({ indexable, meta }) => indexable && meta.use_index.contact
  },
  actions: {
    async fetchData() {
      const repository = factory.get('settings');
      const { master, hallInfo, data: settings } = await repository.index();

      const style = useStyleStore();
      this.$patch({
        ...settings,
        master,
        hallInfo,
        fetching: false
      });
      style.setting(settings.design);
      await this.getHtml();
      await this.updateInfoFromHtml('tag', settings.tag);
      await this.updateInfoFromHtml('layout', settings.layout);
      await this.updateInfoFromHtml('static_elements', settings.static_elements);
    },
    // プレビューの時に、クエリパラメータの値をストアに格納する
    preview(query) {
      Object.entries(query).forEach(([key, value]) => this[queryKeyToActionName(key)](value));
      if (Object.keys(query).length) this.isPreviewActivated = true;
    },
    updateShowHeaderSection(value) {
      this.design.show_header_section = value;
    },
    updateShowFairDescription(value) {
      this.design.show_fair_description = value;
    },
    updateBusinessDayDescription(value) {
      this.form.business_day_description = value;
      this.updateContactType(2);
    },
    updateFairQaNotes(value) {
      this.form.fair_reservation.supplementary_text = value;
    },
    updateFairDoneBookingMsg(value) {
      this.form.fair_reservation.completion_message = value;
    },
    updateContactType(contactType) {
      const contact = useContact();
      contact.$patch({ contactType });
    },
    updateContactQaNotes(value) {
      this.form.contact.supplementary_text = value;
      this.updateContactType(1);
    },
    updateContactDoneBookingMsg(value) {
      this.form.contact.completion_message = value;
      this.updateContactType(1);
    },
    updateVisitQaNotes(value) {
      this.form.visit_reservation.supplementary_text = value;
      this.updateContactType(2);
    },
    updateVisitDoneBookingMsg(value) {
      this.form.visit_reservation.completion_message = value;
      this.updateContactType(2);
    },
    // TODO: DBの整理に合わせてswitch文を削除する
    async updateInfoFromHtml(storeKey, value) {
      const layoutPromise = Object.entries(value).map(async ([key, data]) => {
        // layout/TODO: 削除する
        // tag/TODO: DBごと整理したい
        switch (key) {
          case 'header':
          case 'menu':
          case 'footer':
          case 'script_tag_head':
          case 'script_tag_body':
          case 'no_script_tag':
          case 'link_tag':
          case 'style_tag':
          case 'meta_tag':
          case 'contact_header':
          case 'contact_footer':
          case 'fair_detail_header':
          case 'fair_detail_footer':
          case 'fair_list_header':
          case 'fair_list_footer':
          case 'fair_reservation_header':
          case 'fair_reservation_footer':
          case 'plan_header':
          case 'plan_footer':
          case 'other_header':
          case 'other_footer':
            return data === null ? [] : [key, await this.getHtml(data)];
          default:
            return [];
        }
      });
      const layoutHtml = await Promise.all(layoutPromise);
      this.$patch({ [storeKey]: Object.fromEntries(layoutHtml) });
    },
    async getHtml(url) {
      try {
        if (!url) return null;
        const response = await fetch(url);
        if (response.status !== 200) return null;
        return await response.text();
      } catch (err) {
        return null;
      }
    }
  }
});

export default useSettingStore;
