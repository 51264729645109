import { getValue } from '@/helper/common/object';
import { toLocaleString, isEqual } from '@/helper/common/date';

const getters = {
  isValidSelectDate:
    ({ currentFair }) =>
    (targetDate) =>
      (currentFair.schedules || []).find(({ date }) => isEqual(date, targetDate)),
  numberOfPeopleOptions: () => [
    { value: 1, label: '1人' },
    { value: 2, label: '2人' },
    { value: 3, label: '3人' },
    { value: 4, label: '4人' },
    { value: 5, label: '5人' }
  ],
  weddingGuestOptions: () => [
    { value: '10名以下' },
    { value: '11名～20名' },
    { value: '21名～30名' },
    { value: '31名～40名' },
    { value: '41名～50名' },
    { value: '51名～60名' },
    { value: '61名～70名' },
    { value: '71名～80名' },
    { value: '81名～90名' },
    { value: '91名～100名' },
    { value: '101名～110名' },
    { value: '111名～120名' },
    { value: '121名～130名' },
    { value: '131名～140名' },
    { value: '141名～150名' },
    { value: '151名以上' }
  ],
  weddingBudgetOptions: () => [
    { value: '100万円以下' },
    { value: '101～200万円' },
    { value: '201～300万円' },
    { value: '301～400万円' },
    { value: '401～500万円' },
    { value: '501～600万円' },
    { value: '601～700万円' },
    { value: '701～800万円' },
    { value: '801～900万円' },
    { value: '901～1000万円' },
    { value: '1000万円以上' }
  ],

  params() {
    return {
      fair_name: this.title,
      date: this.selectedDate,
      part: this.textRequestTimetable,
      contents: this.paramRequestContents,
      number_of_attendees: this.textNumberOfPeople,
      family_name: this.family_name,
      first_name: this.first_name,
      family_name_kana: this.family_name_kana,
      first_name_kana: this.first_name_kana,
      gender: this.gender,
      phone_number: this.phone_number,
      mail_address: this.mail_address,
      comment: this.comment,
      age: this.textAge,
      partner_family_name: this.partner_family_name,
      partner_first_name: this.partner_first_name,
      partner_family_name_kana: this.partner_family_name_kana,
      partner_first_name_kana: this.partner_first_name_kana,
      scheduled_wedding_date: this.scheduled_wedding_date,
      wedding_style: this.wedding_style,
      planned_number_of_attendee: this.planned_number_of_attendee,
      spending_limit: this.spending_limit
    };
  },

  /* フェアの詳細 */
  fairId: ({ currentFair }) => currentFair.fair_id,
  title: ({ currentFair }) => currentFair.title,
  fairSchedules: ({ currentFair }) => currentFair.schedules,
  timetables: ({ currentFair }) => currentFair.parts_timetables,
  timetable:
    ({ timetables }) =>
    (timetableId) =>
      timetables.find(({ parts_timetable_id }) => parts_timetable_id === timetableId),
  requestedTimetable: ({ timetables, requestTimetable }) =>
    requestTimetable
      ? timetables.find(({ parts_timetable_id }) => parts_timetable_id === requestTimetable)
      : null,
  contents: ({ currentFair }) => currentFair.contents,
  content:
    ({ contents }) =>
    (contentId) =>
      contents.find(({ content_id }) => content_id === contentId) || {},
  contentTitle:
    ({ content }) =>
    (targetContent) => {
      if (targetContent.title) {
        return targetContent.title;
      }
      const baseContent = content(targetContent.content_id);
      if (baseContent.title) {
        return baseContent.title;
      }
      return targetContent.name;
    },
  partByTimetable:
    ({ timetable }) =>
    (timetableId) => {
      const { part } = timetable(timetableId) || { part: null };
      return part;
    },
  contentsTimetables: ({ currentFair }) => currentFair.contents_timetables,
  contentsTimetable:
    ({ contentsTimetables }) =>
    (targetPart) =>
      contentsTimetables.filter(({ part }) => part === targetPart),
  contentTimetableById:
    ({ contentsTimetables }) =>
    (contentId) =>
      contentsTimetables.find(({ contents_timetable_id }) => contents_timetable_id === contentId),
  requestedContents: ({ requestContents, contentTimetableById }) =>
    requestContents.map((contentId) => contentTimetableById(contentId)),
  schedules: ({ currentFair, selectedDate }) => {
    if (!selectedDate) return {};
    return (currentFair.schedules || []).find(({ date }) => isEqual(date, selectedDate)) || {};
  },
  stocks: ({ schedules }) => schedules.stocks || [],

  /* 残席関係 */
  // 残席の管理方法を取得する
  // true: 部 × コンテンツごと / false: 部ごと
  isStockByContent: ({ currentFair }) =>
    !!getValue(currentFair.schedules[0], 'stocks', 0, 'content', 'id'),
  stock:
    ({ stockByPart, stockByContent }) =>
    (targetPart, contentId) =>
      contentId ? stockByContent(targetPart, contentId) : stockByPart(targetPart),
  stockByPart:
    ({ stocks }) =>
    (targetPart) =>
      stocks.find(({ part }) => part === targetPart),
  stockByContent:
    ({ stocks }) =>
    (targetPart, contentId) =>
      stocks.find(({ part, content }) => part === targetPart && content.id === contentId),
  isStockByPartFull:
    ({ stocks, stockByPart, isStockByContent }) =>
    (targetPart) => {
      // 部 × コンテンツごとで開催される場合は、全てのコンテンツが満席の場合にtrueを返す
      if (isStockByContent) {
        return stocks.every(({ part, type }) => (part === targetPart ? type === 0 : true));
      }
      const { type } = stockByPart(targetPart) || { type: 0 };
      return !type;
    },
  isStockByContentFull:
    ({ stockByContent }) =>
    (part, contentId) => {
      const { type } = stockByContent(part, contentId) || { type: 0 };
      return !type;
    },

  /* 入力確認 */
  textSelectedDate: ({ selectedDate }) => toLocaleString(selectedDate, '日付を選択してください'),
  textRequestTimetable: ({ requestedTimetable }) =>
    requestedTimetable
      ? `【第 ${requestedTimetable.part} 部】 ${requestedTimetable.start_time}～${requestedTimetable.end_time}`
      : null,
  paramRequestContents: ({ requestedContents, contentTitle }) =>
    requestedContents.map(
      (item) => `${contentTitle(item)}（${item.start_time}～${item.end_time}）`
    ),
  textRequestContents: ({ paramRequestContents }) => paramRequestContents.join('\r\n'),
  textRequestTime: ({ textRequestTimetable, textRequestContents }) =>
    `${textRequestTimetable}\r\n${textRequestContents}`,
  textNumberOfPeople: ({ number_of_attendees }) => `${number_of_attendees}名`,
  textName: ({ family_name, first_name }) => [family_name, first_name, '様'].join('　'),
  textNameRuby: ({ family_name_kana, first_name_kana }) => [family_name_kana, first_name_kana].join('　'),
  textAge: ({ age }) => (age ? `${age}歳` : null),
  textPartnerName: ({ partner_family_name, partner_first_name }) =>
  partner_family_name || partner_first_name
      ? [partner_family_name, partner_first_name, '様'].join('　')
      : null,
  textPartnerNameRuby: ({ partner_family_name_kana, partner_first_name_kana }) =>
  partner_family_name_kana || partner_first_name_kana
      ? [partner_family_name_kana, partner_first_name_kana].join('　')
      : null,
  shouldShowOptionalItems: ({ showOptionalItems }) => showOptionalItems,
};

export default getters;
