import { defineStore } from 'pinia';

import useCommonStore from '@/store/common';

import factory from '@/repositories/repository-factory';

const useFormItemSettingStore = defineStore('formItemSetting', {
  state: () => ({
    fair_reservation: [],
    contact: [],
    visit_reservation: []
  }),
  getters: {
    fairReservationSettings: ({ fair_reservation }) => fair_reservation,
    fairReservationSetting:
      ({ fair_reservation }) =>
      (itemName) =>
        fair_reservation.find(({ item }) => item === itemName) || null,
    contactSettings: ({ contact }) => contact,
    contactSetting:
      ({ contact }) =>
      (itemName) =>
        contact.find(({ item }) => item === itemName) || null,
    visitReservationSettings: ({ visit_reservation }) => visit_reservation,
    visitReservationSetting:
      ({ visit_reservation }) =>
      (itemName) =>
        visit_reservation.find(({ item }) => item === itemName) || null
  },
  actions: {
    async fetchFormItemSetting() {
      const { updateLoading } = useCommonStore();
      updateLoading(true);
      const repository = factory.get('formItemSettings');
      const { data } = await repository.index();
      if (data) {
        this.$patch(data);
      }
      updateLoading(false);
    }
  }
});

export default useFormItemSettingStore;
