import { defineStore } from 'pinia';

import factory from '@/repositories/repository-factory';

const useScheduleStore = defineStore('schedule', {
  state: () => ({
    schedules: [],
    calendar: null
  }),
  getters: {
    isHoliday:
      ({ calendar }) =>
      (year, month, date) => {
        if (calendar === null) {
          return false;
        }

        const monthCalendar = calendar[`${year}-${month}`];
        if (!monthCalendar) {
          return false;
        }

        const calendarItem = monthCalendar.find(({ day }) => day === date);
        return calendarItem ? calendarItem.is_holiday : false;
      }
  },
  actions: {
    async fetchSchedules() {
      if (this.schedules.length > 0) {
        return;
      }

      const schedule = factory.get('schedules');
      const { data: schedules } = await schedule.index();
      this.$patch({ schedules });
    },
    async fetchCalendar() {
      if (this.calendar !== null) {
        return;
      }

      const schedule = factory.get('schedules');
      const { data } = await schedule.calendarIndex();
      const { calendar } = data;
      this.$patch({ calendar });
    }
  }
});

export default useScheduleStore;
