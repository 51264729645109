import factory from '@/repositories/repository-factory';

import useFairReservation from '@/store/fair-reservation';
import useSettingStore from '@/store/setting';

import insertStaticElement from './insert-static-element';

const PreviewFairReservation = () =>
  import(/* webpackChunkName: "preview-fair-reservation" */ '@/views/PreviewFairReservation.vue');
const PreviewContact = () =>
  import(/* webpackChunkName: "preview-contact" */ '@/views/PreviewContact.vue');

const previewRoutes = [
  {
    path: '/preview/fair-reservation',
    name: 'preview-fair-reservation',
    component: PreviewFairReservation,
    beforeEnter: async (_, __, next) => {
      const repository = factory.get('fairs');
      const { data: fairs } = await repository.index({ per_page: 1 });

      const store = useFairReservation();
      store.$reset();

      const currentFair = fairs[0];
      if (currentFair) store.$patch({ currentFair });

      const { fairReservationHeader, fairReservationFooter } = useSettingStore();
      insertStaticElement(fairReservationHeader, fairReservationFooter);
      next();
    }
  },
  {
    path: '/preview/contact',
    name: 'preview-contact',
    component: PreviewContact,
    beforeEnter: async (_, __, next) => {
      const { contactHeader, contactFooter } = useSettingStore();
      insertStaticElement(contactHeader, contactFooter);
      next();
    }
  }
];

export default previewRoutes;
