/**
 * サーバー環境情報
 */
const url = process.env.VUE_APP_LOCAL_URL;
const urlDev = process.env.VUE_APP_DEV_URL;
// ドメインリスト
export const SERVER_DOMAIN = {
  local: url,
  develop: urlDev,
  productionAdmin: 'tenomeroc.jp', // ステージング環境
  production: 'wedding-site.jp'
};
Object.freeze(SERVER_DOMAIN);

// API ベース URL リスト
const port = process.env.VUE_APP_API_PORT;
const apiUrlDev = process.env.VUE_APP_API_DEV_URL;
export const API_BASE_URL = {
  local: `http://${url}:${port}/external/api/v1`,
  develop: `https://${apiUrlDev}/external/api/v1`,
  productionAdmin: 'https://api.tenomeroc.jp/external/api/v1', // ステージング環境
  production: 'https://api.wedding-site.jp/external/api/v1'
};
Object.freeze(API_BASE_URL);

/**
 * サーバー確認用フラグ
 */
// eslint-disable-next-line no-restricted-globals
const { hostname } = location;

// デフォルト動作モード
const MODE = Object.keys(SERVER_DOMAIN).find(
  (mode) => hostname.endsWith(SERVER_DOMAIN[mode]) || mode === 'production'
);

/**
 * サーバー設定を取得
 */
export const getServerConfig = () => {
  const config = {
    apiBaseUrl: API_BASE_URL[MODE],
    mode: MODE
  };
  return config;
};
