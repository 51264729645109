import useFairStore from '@/store/fair';
import useSettingStore from '@/store/setting';
import useScheduleStore from '@/store/schedule';

import insertStaticElement from './insert-static-element';

const DailyFair = () => import(/* webpackChunkName: "daily-fair" */ '@/views/DailyFair.vue');

const dailyFair = {
  path: '/daily-fairs/:id/:date',
  name: 'daily-fair',
  component: DailyFair,
  beforeEnter: async (to, _, next) => {
    const { fetchFair } = useFairStore();
    const isDaily = true;
    await fetchFair(to.params, isDaily);

    const { fetchCalendar } = useScheduleStore();
    fetchCalendar();

    const { fairDetailHeader, fairDetailFooter } = useSettingStore();
    insertStaticElement(fairDetailHeader, fairDetailFooter);
    next();
  }
};

export default dailyFair;
