import useContactStore from '@/store/contact';
import useSettingStore from '@/store/setting';
import useFormItemSettingStore from '@/store/form-item-setting';

import insertStaticElement from './insert-static-element';

const Contact = () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue');

const contact = {
  path: '/contact',
  name: 'contact',
  component: Contact,
  beforeEnter: async (to, _, next) => {
    if (to.query.contactType) {
      const { contactTypes, updateContactType } = useContactStore();
      const targetType = contactTypes.find(({ query }) => query === to.query.contactType);
      if (targetType) {
        updateContactType(parseInt(targetType.value, 10));
      }
    }

    const { fetchFormItemSetting } = useFormItemSettingStore();
    await fetchFormItemSetting();

    const { contactHeader, contactFooter } = useSettingStore();
    insertStaticElement(contactHeader, contactFooter);
    next();
  }
};

export default contact;
