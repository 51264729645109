import useCommonStore from '@/store/common';
import useFairStore from '@/store/fair';
import useSettingStore from '@/store/setting';

export const updateMetadata = (selectorName, selectorValue, content) => {
  const selector = document.querySelector(`meta[${selectorName}='${selectorValue}']`);
  if (selector) {
    selector.setAttribute('content', content);
  }
};

function setTag(appendTargetElement, tagName, element, shouldPrepend = false) {
  return new Promise((resolve) => {
    const tag = document.createElement(tagName);
    element.getAttributeNames().forEach((attributeName) => {
      tag.setAttribute(attributeName, element.getAttribute(attributeName));
    });
    tag.innerHTML = element.innerHTML;

    if (shouldPrepend) {
      appendTargetElement.prepend(tag);
    } else {
      appendTargetElement.appendChild(tag);
    }
    resolve();
  });
}

const setFavicon = (favicon_url) => {
  const faviconLinkElement =
    document.querySelector("link[rel*='icon']") || document.createElement('link');
  faviconLinkElement.href = favicon_url;
  document.head.appendChild(faviconLinkElement);
};

const createTag = async (htmlSource, tagName, distHtml) => {
  const parser = new DOMParser();
  const parseHtml = parser.parseFromString(htmlSource, 'text/html');
  const tags = [...parseHtml.querySelectorAll(tagName)];
  for (let i = 0; i < tags.length; i += 1) {
    const script = tags[i];
    // eslint-disable-next-line no-await-in-loop
    await setTag(distHtml, tagName, script);
  }
};

const tagParams = (routeName) => {
  if (routeName === 'fairs' || routeName === 'daily-fairs') {
    const { useIndexFairList, page } = useSettingStore();
    const index = useIndexFairList ? 'all' : 'noindex, nofollow';
    const pageTitle = page.fair_list_title;
    return { index, pageTitle };
  }

  if (routeName === 'fair' || routeName === 'daily-fair') {
    const { useIndexFairDetail, page } = useSettingStore();
    const index = useIndexFairDetail ? 'all' : 'noindex, nofollow';

    const { fairTitle } = useFairStore();
    const pageTitle = fairTitle + page.fair_list_title;
    return { index, pageTitle };
  }

  if (routeName === 'plans') {
    const { useIndexPlan, page } = useSettingStore();
    const index = useIndexPlan ? 'all' : 'noindex, nofollow';
    const pageTitle = page.plan_list_title;
    return { index, pageTitle };
  }

  if (routeName === 'contact') {
    const { useIndexContact } = useSettingStore();
    const index = useIndexContact ? 'all' : 'noindex, nofollow';
    return { index };
  }

  if (routeName.includes('polic')) {
    const index = 'noindex, nofollow';
    const pageTitle = 'プライバシーポリシー';
    return { index, pageTitle };
  }

  return { index: 'noindex, nofollow' };
};

export const appendTags = async (routeName) => {
  const { updateAppendingTag } = useCommonStore();
  const { meta, tag } = useSettingStore();
  const { index, pageTitle } = tagParams(routeName);

  updateAppendingTag(true);
  // indexの設定
  updateMetadata('name', 'robots', index);
  // faviconの設定
  setFavicon(tag.favicon_url);
  // titleの設定
  document.title = pageTitle || '';
  updateMetadata('property', 'og:title', meta.meta_description || '');
  // タグの設定
  updateMetadata('name', 'keywords', meta.meta_keyword || '');
  // 各種タグの作成
  await createTag(tag.link_tag, 'link', document.head);
  await createTag(tag.script_tag_head, 'script', document.head);
  await createTag(tag.script_tag_body, 'script', document.body);
  await createTag(tag.style_tag, 'style', document.head);
  // descriptionの設定
  updateMetadata('name', 'description', meta.meta_description || '');
  updateMetadata('property', 'og:description', meta.meta_description || '');
  updateAppendingTag(false);
};
