import useFairStore from '@/store/fair';
import useSettingStore from '@/store/setting';
import useScheduleStore from '@/store/schedule';

import insertStaticElement from './insert-static-element';

const DailyFairs = () => import(/* webpackChunkName: "daily-fairs" */ '@/views/DailyFairs.vue');

const dailyFairs = {
  path: '/daily-fairs',
  name: 'daily-fairs',
  component: DailyFairs,
  beforeEnter: async (to, _, next) => {
    const { fetchInitialFairs } = useFairStore();
    const isDaily = true;
    await fetchInitialFairs(to.query, isDaily);

    const { fetchSchedules, fetchCalendar } = useScheduleStore();
    fetchSchedules();
    fetchCalendar();

    const { fairListHeader, fairListFooter } = useSettingStore();
    insertStaticElement(fairListHeader, fairListFooter);
    next();
  }
};

export default dailyFairs;
