/**
 * 対象のObjectが指定したプロパティを持っているかどうかを判定する
 * @param {*} object
 * @param {*} key
 */
export const hasProperty = (object, key) =>
  !!object && Object.prototype.hasOwnProperty.call(object, key);

/*
 * ネスト構造のオブジェクトから指定値を取得（recursive）
 * params targetObj <Object> 対象のオブジェクト
 * params key <String> 取得する値のキー
 *
 * ex. getValue(obj, key1, key2, key3...)
 */
export const getValue = (targetObj, ...keys) => {
  if (targetObj === undefined || targetObj === null) return null;

  const [key, ...nextKeys] = keys;
  const nextObj = targetObj[key];

  if (nextKeys.length === 0) return nextObj;
  return getValue(nextObj, ...nextKeys);
};

/*
 * オブジェクトから指定キーの順位で値を優先取得、なければデフォルト値を取得
 * params targetObj <Object> 対象のオブジェクト
 * params preferableKeys <Array> 取得するキー（順位番）
 * params getDefault <Function> デフォルト値の判定
 *
 * ex. getPreferableValue(obj, [preferableKey1, preferableKey2 ...])
 * preferableKey の値があれば return、もしくは次の順番のキーの指定値を取得
 */
export const getPreferableValue = (
  targetObj,
  preferableKeys,
  getDefault = (value) => value !== null
) => {
  const preferableKey = preferableKeys.find(
    (key) => hasProperty(targetObj, key) && targetObj[key] !== null
  );
  return preferableKey ? targetObj[preferableKey] : Object.values(targetObj).find(getDefault);
};

/*
 * クエリするパラメータと値を API get 用の文字列に整形
 * params params <Object> クエリするパラメータ
 * params enableParams <Array> クエリできるキー
 *
 * ex. toQueryString({key1: value1, key2: value2, ... }, [key1, key2, ...])
 * return "?key1=value1&key2=value2"
 */
export const toQueryString = (params, enableParams) => {
  const newParams = Object.entries(params).filter(([key]) =>
    enableParams ? enableParams.includes(key) : true
  );

  if (!newParams.length) return '';

  const paramString = newParams.map(([key, value]) => `${key}=${value}`).join('&');
  return `?${paramString}`;
};

/*
 * オブジェクトから指定キーを抽出
 */
export const extractByKeys = (target, enableParams) => {
  const object = enableParams.reduce((obj, param) => {
    if (!hasProperty(target, param)) return obj;
    return {
      ...obj,
      [param]: target[param] === 'null' ? null : target[param]
    };
  }, {});
  return { ...object };
};
