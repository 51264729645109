<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { updateMetadata } from '@/composable/appendTags';

import PageTitle from '@/components/common/PageTitle.vue';
import StatusCodes from '@/constants/StatusCodes';

const error = ref(null);

const notFound = computed(() => error.value === StatusCodes.notFound);
const errorTitle = computed(() => (notFound.value ? '404 ERROR' : '500 SERVER ERROR'));
const errorDescription = computed(() => (notFound.value ? 'アクセスエラー' : 'サーバーエラー'));
const errorMessage = computed(() => {
  if (notFound.value) {
    return `ご希望のページが見つかりませんでした。
アクセス先に間違いがないか、ご確認ください。`;
  }
  return 'ご不便をおかけして申し訳ございませんが、今しばらくお待ちください。';
});

const errorStatus = (err) => {
  if (err instanceof URIError) {
    return parseInt(err.message, 10);
  }

  const isNumber = typeof err === 'number';
  return isNumber ? err : 500;
};
const router = useRouter();
router.onError((err) => {
  error.value = errorStatus(err);
  document.title = errorDescription.value;
  updateMetadata('name', 'robots', 'noindex');
});
</script>

<template>
  <article v-if="error" class="crmn-error-boundary">
    <PageTitle :title="errorTitle" :description="errorDescription" />
    <div class="crmn-error-boundary__article">
      {{ errorMessage }}
    </div>
  </article>
  <slot v-else />
</template>

<style lang="scss" scoped>
.crmn-error-boundary {
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;

  &__article {
    font-size: variables.$FONT_SIZE_SMALLER;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    white-space: pre-line;

    @include mixins.responsive(lg) {
      font-size: variables.$FONT_SIZE_M;
    }
  }
}
</style>
