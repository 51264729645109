import factory from '@/repositories/repository-factory';

import usePlanStore from '@/store/plan';
import useSettingStore from '@/store/setting';

import insertStaticElement from './insert-static-element';

const Plans = () => import(/* webpackChunkName: "plans" */ '@/views/Plans.vue');

const plans = {
  path: '/plans',
  name: 'plans',
  component: Plans,
  beforeEnter: async (to, _, next) => {
    const repository = factory.get('plans');
    const store = usePlanStore();
    const fetchDefinition = repository.definition();
    const fetchRecommendedPlans = repository.index({ recommended: true });
    const fetchPlans = store.fetchPlansByParams(to.query);
    const promises = [fetchDefinition, fetchRecommendedPlans, fetchPlans];
    const [{ data: definition }, { data: recommendedPlans }] = await Promise.all(promises);

    store.$patch({ definition, recommendedPlans });

    const { planHeader, planFooter } = useSettingStore();
    insertStaticElement(planHeader, planFooter);
    next();
  }
};

export default plans;
