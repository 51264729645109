export default {
  success: 200,
  created: 201,
  accepted: 202,
  deleted: 204,
  // 以下、エラーコード
  badRequest: 400,
  unauthorized: 401,
  accessDenied: 403,
  notFound: 404,
  conflict: 409,
  sync: 422,
  locked: 423,
  internalError: 500
};
