import { addDays, isBefore } from 'date-fns';

import { newDate } from './common/date';

// コンテンツ一覧から予約が必要かどうかのラベルを取得する
export const reservationLabel = (contents) => {
  const reservationType = contents.reduce((currentType, { reservation_type }) => {
    if (!currentType) {
      return reservation_type;
    }
    return reservation_type.type > currentType.type ? reservation_type : currentType;
  }, null);

  return reservationType.name;
};

// コンテンツ一覧から料金区分のラベルを取得する
export const chargeTypeLabel = (contents) => {
  const reservationType = contents.reduce((currentType, { charge_type }) => {
    if (!currentType) {
      return charge_type;
    }
    return charge_type.type > currentType.type ? charge_type : currentType;
  }, null);

  return reservationType.name;
};

// 分を時間に変換する
export const convertHourAndMinute = (duration) => {
  const hour = Math.floor(duration / 60);
  const min = duration % 60;
  return min === 0 ? `${hour}時間` : `${hour}時間${min}分`;
};

/**
 * フェア一覧（日毎）開催日フェア
 * ソートのための比較関数
 * @param {object} a
 * @param {object} b
 */
export const sortFairs = (a, b) => {
  // 開始時間(昇順)
  if (a.total_start_time !== b.total_start_time) return a.total_start_time - b.total_start_time;

  // 終了時間(昇順)
  if (a.total_end_time !== b.total_end_time) return a.total_end_time - b.total_end_time;

  // ID(降順)
  return a.fair_id - b.fair_id;
};

/**
 * フェア一覧（日毎）オススメフェア
 * ソートのための比較関数
 * @param {object} a
 * @param {object} b
 */
export const sortRecommend = (a, b) => {
  // スケジュールが存在しない場合はそのまま返す
  if (a.schedules.length < 1 || b.schedules.length < 1) {
    return 0;
  }

  // 開催日(昇順)
  const dateA = a.schedules[0].date;
  const dateB = b.schedules[0].date;
  if (dateA !== dateB) return newDate(dateA) - newDate(dateB);

  return sortFairs(a, b);
};

/**
 * 予約可能な日付かどうかを取得する
 */
export const reservable = (eventDate, untilDays, untilTime) => {
  if (untilDays === null) {
    return true;
  }
  const date = newDate(`${eventDate} ${untilTime}`);
  // 時間が設定されていない場合、翌日の0時まで予約可能とする
  const days = untilTime ? untilDays : untilDays - 1;
  const reservableDay = addDays(date, -parseInt(days, 10));
  return isBefore(new Date(), reservableDay);
};
/**
 * 電話予約可能な日付かどうかを取得する
 */
export const reservableByPhone = (fair, date) => {
  const day = fair.book_by_phone_available_until_days.value;
  const time = fair.book_by_phone_available_until_time || "";
  return reservable(date, day, time);
};

/**
 * オンライン予約可能な日付かどうかを取得する
 */
export const reservableByOnline = (fair, date) => {
  const day = fair.book_online_available_until_days.value;
  const time = fair.book_online_available_until_time || "";
  return reservable(date, day, time);
};
