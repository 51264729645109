import { defineStore } from 'pinia';
import useStyleStore from '@/store/style';
import useSettingStore from '@/store/setting';
import { extractByKeys } from '@/helper/common/object';

const usePreviewStore = defineStore('preview', {
  state: () => ({
    active: false
  }),
  actions: {
    init(params) {
      const style = useStyleStore();
      const stylePreviewableParams = [
        'mainColor',
        'keyColor',
        'accentColor',
        'navigationColor',
        'textColor'
      ];
      style.preview(extractByKeys(params, stylePreviewableParams));

      const setting = useSettingStore();
      const settingPreviewableParams = [
        'businessDayDescription',
        'fairQaNotes',
        'fairDoneBookingMsg',
        'contactQaNotes',
        'contactDoneBookingMsg',
        'visitQaNotes',
        'visitDoneBookingMsg',
        'showHeaderSection',
        'showFairDescription'
      ];
      setting.preview(extractByKeys(params, settingPreviewableParams));

      const isActivated = Object.keys(params).some((key) =>
        [...stylePreviewableParams, ...settingPreviewableParams].includes(key)
      );
      this.active = isActivated;
    }
  }
});

export default usePreviewStore;
