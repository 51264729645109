import { defineStore } from 'pinia';

import RECOMMEND_TYPE from '@/constants/RecommendType';

import { isEqual } from '@/helper/common/date';
import { sortRecommend, sortFairs } from '@/helper/fair';

import useCommonStore from '@/store/common';
import useSettingStore from '@/store/setting';

import factory from '@/repositories/repository-factory';

const useFairStore = defineStore('fair', {
  state: () => ({
    dailyFairs: {},
    fairs: [],
    recommendedFairs: [],
    links: {},
    meta: {},
    currentFair: {},
    definition: {}
  }),
  getters: {
    fair:
      ({ fairs }) =>
      (fairId) =>
        fairs.find(({ fair_id }) => fair_id === fairId),
    recommendedFairByDate:
      ({ fair }) =>
      (fairIds, targetDate) => {
        const recommendedFairId = fairIds.find((fairId) => {
          const schedule = fair(fairId).schedules.find(({ date }) => date === targetDate);
          if (!schedule) return null;
          return schedule.recommended_daily.find(
            ({ type }) => type === RECOMMEND_TYPE.RECOMMENDATION_DAILY_HP
          );
        });
        return recommendedFairId ? fair(recommendedFairId) : null;
      },
    fairsByDate:
      ({ fair, recommendedFairByDate, dailyFairs }) =>
      (targetDate) => {
        const fairIds = dailyFairs[targetDate];
        const fairs = fairIds.map((fairId) => fair(fairId)).sort(sortFairs);
        const mainFair = recommendedFairByDate(fairIds, targetDate) || fairs[0];
        const sameDateFairs = fairs.filter(({ fair_id }) => fair_id !== mainFair.fair_id);

        return { mainFair, sameDateFairs };
      },
    fairPartStocksByDate:
      ({ defStock }) =>
      (currentFair, targetDate) => {
        const schedule = currentFair.schedules.find(({ date }) => isEqual(date, targetDate));
        const stocks = schedule.stocks || [];

        return currentFair.parts_timetables.map((timeTable) => {
          const stocksByPart = stocks.filter(({ part }) => part === timeTable.part);

          const getStockType = (stock) => {
            if (stock.length === 0) return 0;
            return stock.every(({ type }) => type === stock[0].type) ? stock[0].type : 1;
          };

          const stockType = getStockType(stocksByPart);
          return {
            ...timeTable,
            ...defStock(stockType)
          };
        });
      },
    currentSameDateFairs:
      ({ currentFair, fairsByDate }) =>
      (date) => {
        const fairs = fairsByDate(date);
        return Object.values(fairs)
          .flat()
          .filter(({ fair_id }) => fair_id !== currentFair.fair_id);
      },
    fairTitle: ({ currentFair }) => currentFair.title,

    // definition から各種定義情報を取得する
    defContents() {
      return this.definition.contents;
    },
    defSchedules() {
      return this.definition.schedules;
    },
    defStock:
      ({ defSchedules }) =>
      (stockType) =>
        defSchedules.stock_types[stockType],
    defTags() {
      return this.definition.tags;
    },
    // 表示するフェア一覧がフェアマスタか日毎フェアかを取得する
    isDaily: () => {
      const { fairListType } = useSettingStore();
      return fairListType === '1';
    }
  },
  actions: {
    /**
     * 画面の初期表示時にフェア一覧などの情報を取得する
     * @param {*} query
     * @param {boolean} isDaily
     *
     * MEMO: router/index.jsで`/daily-fairs/:id/:date`がなくなったら第二引数を削除する
     */
    async fetchInitialFairs(query = {}, isDaily = false) {
      const fair = factory.get('fairs');
      const useDaily = isDaily || this.isDaily;
      const fetchFairs = useDaily ? this.fetchDailyFairs : this.fetchFairs;
      const promises = [
        fair.definition(),
        fetchFairs(query, true),
        this.fetchRecommendedFairs(true, useDaily)
      ];

      this.updateLoading(true);
      const [{ data: definition }] = await Promise.all(promises);
      this.$patch({ definition });
      this.updateLoading(false);
    },
    /**
     * フェア一覧を取得する
     * @param {*} query
     * @param {*} loading
     */
    async fetchFairs(query = {}, loading = false) {
      this.updateLoading(true);
      const fair = factory.get('fairs');
      const { links, meta, data: fairs } = await fair.index(query);
      this.$patch({ links, meta, fairs });
      this.updateLoading(loading);
    },
    /**
     * 日毎フェア一覧を取得する
     * @param {*} query
     * @param {*} loading
     */
    async fetchDailyFairs(query = {}, loading = false) {
      this.updateLoading(true);
      const fair = factory.get('fairs');
      const { links, meta, fairs, data: dailyFairs } = await fair.dailyIndex(query);
      this.dailyFairs = dailyFairs;
      this.$patch({ links, meta, fairs });
      this.updateLoading(loading);
    },
    /**
     * オススメフェアを取得する
     * @param {*} loading
     */
    async fetchRecommendedFairs(loading = false, isDaily = false) {
      this.updateLoading(true);
      const fair = factory.get('fairs');
      const fetchFairs = isDaily ? fair.dailyIndex : fair.index;
      const response = await fetchFairs({ recommended: true });
      const recommendedFairs = isDaily
        ? [...response.fairs].sort(sortRecommend)
        : [...response.data].sort(sortFairs);

      this.$patch({ recommendedFairs });
      this.updateLoading(loading);
    },
    /**
     * フェア詳細を取得する
     * @param {*} param0
     * @param {boolean} isDaily
     *
     * MEMO: router/index.jsで`/daily-fairs/:id/:date`がなくなったら第二引数を削除する
     */
    async fetchFair({ id, date }, isDaily = false) {
      const fair = factory.get('fairs');
      const useDaily = isDaily || this.isDaily;
      const fetchFair = useDaily ? fair.dailyShow : fair.show;
      const fetchFairs = useDaily ? fair.dailyIndex : fair.index;
      const fairsQuery = useDaily ? { date } : { recommended: true };
      const promises = [fetchFairs(fairsQuery), fetchFair(id, date), fair.definition()];
      const [fairsResponse, { data: currentFair }, { data: definition }] = await Promise.all(
        promises
      );
      const fairs = useDaily ? fairsResponse.fairs : fairsResponse.data;
      const dailyFairs = useDaily ? fairsResponse.data : {};
      this.$patch({ currentFair, definition, dailyFairs, fairs });
    },
    updateLoading(loading) {
      const { updateLoading } = useCommonStore();
      updateLoading(loading);
    }
  }
});

export default useFairStore;
