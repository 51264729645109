import factory from '@/repositories/repository-factory';

import useFairStore from '@/store/fair';
import useFairReservation from '@/store/fair-reservation';
import useSettingStore from '@/store/setting';
import useScheduleStore from '@/store/schedule';
import useFormItemSettingStore from '@/store/form-item-setting';

import insertStaticElement from './insert-static-element';

const FairReservation = () =>
  import(/* webpackChunkName: "fair-reservation" */ '@/views/FairReservation.vue');

const fairReservation = {
  path: '/fair-reservation/:id',
  name: 'fair-reservation',
  component: FairReservation,
  beforeEnter: async (to, _, next) => {
    const currentFair = await (async () => {
      const { currentFair: fairCache } = useFairStore();
      if (Object.keys(fairCache).length) return fairCache;

      const repository = factory.get('fairs');
      const { id } = to.params;
      const { data } = await repository.show(id);
      return data;
    })();
    const store = useFairReservation();
    store.$reset();
    store.$patch({ currentFair });

    const { fetchFormItemSetting } = useFormItemSettingStore();
    await fetchFormItemSetting();

    const { fetchCalendar } = useScheduleStore();
    fetchCalendar();

    const { fairReservationHeader, fairReservationFooter } = useSettingStore();
    insertStaticElement(fairReservationHeader, fairReservationFooter);
    next();
  }
};

export default fairReservation;
