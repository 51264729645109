import { createRouter, createWebHistory } from 'vue-router';

import { appendTags } from '@/composable/appendTags';

import { fetchToken } from '@/repositories/repository';

import useCommonStore from '@/store/common';
import useSettingStore from '@/store/setting';
import usePreviewStore from '@/store/preview';
import fairs from './fairs';
import fair from './fair';
import fairReservation from './fair-reservation';
import dailyFairs from './daily-fairs';
import dailyFair from './daily-fair';
import plans from './plans';
import contact from './contact';
import policies from './policies';
import previewRoutes from './preview';

const clearAppStyle = () => {
  const appElement = document.getElementById('app');
  appElement.style.marginTop = '';
};

const index = {
  path: '/',
  name: 'index',
  redirect: { name: 'fairs' }
};

const routes = [
  ...previewRoutes,
  index,
  fairs,
  fair,
  fairReservation,
  dailyFairs,
  dailyFair,
  plans,
  contact,
  policies
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    const toSamePage = to.name === from.name;
    const toListPage = ['index', 'fairs', 'daily-fairs', 'plans'].includes(to.name);
    const keepPostion = toSamePage && toListPage && !to.query.page;
    return keepPostion ? savedPosition : { top: 0 };
  },
  routes
});

router.beforeResolve((to) => {
  // プレビュー
  if (to.query) {
    usePreviewStore().init(to.query);
  }
});

router.beforeEach(async ({ name }) => {
  if (!name) {
    throw new URIError(404);
  }

  const store = useCommonStore();
  if (!store.token) {
    const token = await fetchToken();
    store.$patch({ token });
  }

  const setting = useSettingStore();
  setting.$reset();
  await setting.fetchData();
});

router.afterEach(async (to) => {
  await appendTags(to.name);
  clearAppStyle();
});

export default router;
