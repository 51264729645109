import { format, startOfDay, isEqual as $isEqual, isValid as $isValid } from 'date-fns';
import ja from 'date-fns/locale/ja';

const SUNDAY_CODE = 0;
const SATURDAY_CODE = 6;

export const newDate = (dateString, time) => {
  const date = dateString.replace(/-/g, '/');
  const dateWithTime = time ? `${date} ${time}` : date;
  return new Date(dateWithTime);
};

export const isValid = (date) => date && $isValid(newDate(date));

/**
 * スタイリング用のクラスを生成する
 */
export const weekdayClass = (code) => ({
  sunday: code === SUNDAY_CODE,
  saturday: code === SATURDAY_CODE
});

/*
 * 日付け表示の整形
 * ex. 2022-12-31 → 2022年12月31日
 */
export const toLocaleString = (date, errorMsg) => {
  if (!isValid(date)) return errorMsg || '正しい日付を入力してください';
  return format(newDate(date), 'yyyy年MM月dd日');
};

/*
 * 日本語曜日を取得
 */
export const getWeekDay = (date) => {
  if (!isValid(date)) return null;
  return format(newDate(date), 'E', { locale: ja });
};

export const getWeekend = (date) => {
  if (!isValid(date)) return '';

  const code = newDate(date).getDay();
  return weekdayClass(code);
};

export const isEqual = (date1, date2) => {
  const targetDate1 = startOfDay(newDate(date1));
  const targetDate2 = startOfDay(newDate(date2));
  return $isEqual(targetDate1, targetDate2);
};
