import axios from 'axios';
import { getServerConfig } from '../assets/ServerConfig';

const config = getServerConfig();

export const fetchToken = async () => {
  const response = await axios
    .create({
      baseURL: config.apiBaseUrl
    })
    .get('token');
  return response.data.token;
};

export const getAxiosInstance = (token) => axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export default (resource, token) => ({
    index: async (query = '') => {
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}${query}`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    },
    store: async (payload) => {
      const axiosInstance = getAxiosInstance(token);
      axiosInstance.post(resource, payload);
    },
    show: async (id) => {
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}/${id}`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    },
    update: async (id, payload) => {
      const axiosInstance = getAxiosInstance(token);
      axiosInstance.put(`${resource}/${id}`, payload);
    },
    destroy: async (id) => {
      const axiosInstance = getAxiosInstance(token);
      axiosInstance.delete(`${resource}/${id}`);
    }
  });
