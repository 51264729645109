<script setup>
import useCommonStore from '@/store/common';

import ErrorBoundary from '@/components/common/ErrorBoundary.vue';
import Loading from '@/components/common/Loading.vue';

const commonStore = useCommonStore();
</script>

<template>
  <div class="crmn">
    <ErrorBoundary>
      <router-view />
      <Loading v-if="commonStore.loading" data-crmn-loading />
    </ErrorBoundary>
  </div>
</template>
