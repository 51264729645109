const state = () => ({
  selectedDate: null,
  currentFair: {},
  requestTimetable: null,
  requestContents: [],
  number_of_attendees: 2,
  family_name: null,
  first_name: null,
  family_name_kana: null,
  first_name_kana: null,
  gender: '女性',
  phone_number: null,
  mail_address: null,
  comment: null,
  age: null,
  partner_family_name: null,
  partner_first_name: null,
  partner_family_name_kana: null,
  partner_first_name_kana: null,
  scheduled_wedding_date: null,
  wedding_style: null,
  planned_number_of_attendee: null,
  spending_limit: null,
  showOptionalItems: false,
});

export default state;
