import useFairStore from '@/store/fair';
import useSettingStore from '@/store/setting';
import useScheduleStore from '@/store/schedule';

import insertStaticElement from './insert-static-element';

const Fair = () => import(/* webpackChunkName: "fair" */ '@/views/Fair.vue');
const DailyFair = () => import(/* webpackChunkName: "daily-fair" */ '@/views/DailyFair.vue');

const fair = {
  path: '/fairs/:id/:date?',
  name: 'fair',
  component: () => {
    const { isDaily } = useFairStore();
    return isDaily ? DailyFair() : Fair();
  },
  beforeEnter: async (to, _, next) => {
    const { fetchFair, isDaily } = useFairStore();
    // フェアマスタの表示方法が「開催日毎のフェア一覧」にすると日付けを確認する
    if (isDaily && !to.params.date) {
      next('/fairs');
      return;
    }

    await fetchFair(to.params);

    const { fetchCalendar } = useScheduleStore();
    fetchCalendar();

    const { fairDetailHeader, fairDetailFooter } = useSettingStore();
    insertStaticElement(fairDetailHeader, fairDetailFooter);
    next();
  }
};

export default fair;
