import { toQueryString } from '@/helper/common/object';
import Repository, { getAxiosInstance } from './repository';

export default (token) => {
  const resource = 'plans';
  const { index, show } = Repository(resource, token);

  return {
    show,
    index: (query = {}) => {
      const enableParams = [
        'text',
        'contents',
        'with_unpublished',
        'recommended',
        'per_page',
        'page'
      ];
      const queryString = toQueryString(query, enableParams);
      return index(queryString);
    },
    definition: async () => {
      try {
        const axiosInstance = getAxiosInstance(token);
        const { data } = await axiosInstance.get(`${resource}/definition`);
        return data;
      } catch ({ response }) {
        throw response.status;
      }
    }
  };
};
